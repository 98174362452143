import './HomePage.css'
import React from 'react';
import Blog from '../components/blog/Blog'

const HomePage = () => {
    return (
        <div>
            <Blog/>
        </div>
    );
};

export default HomePage;