import './SecondPage.css'
import React from 'react';
import Dashboard from '../components/dashboard/Dashboard'

const SecondPage = () => {
    return (
        <div>
            <Dashboard/>
        </div>
    );
};

export default SecondPage;